import { NgModule } from '@angular/core';
// Routes
import { RouterModule, Routes } from '@angular/router';
// Guard service
import { CanActiveViaAuthGuard } from './core/services/auth-guard.service';
// Components
import { HomeComponent } from './pages/home/home.component';
import { SitemapComponent } from './pages/sitemap/sitemap.component';
import { FaqComponent } from './pages/faq/faq.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { LandingOfferComponent } from './pages/landing-offer/landing-offer.component';
import { EducationBlogComponent } from './pages/education-blog/education-blog.component';
import { EducationBlogPostComponent } from './pages/education-blog/education-blog-post/education-blog-post.component';
import { DevToolsGuardGuard } from './core/services/dev-tools-guard.guard';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full', canActivate: [CanActiveViaAuthGuard] },

  // Lazy loading
  { path: 'categoria', loadChildren: () => import('./modules/categorias/categorias.module').then(m => m.CategoriasModule) },
  { path: 'perfil-bc', loadChildren: () => import('./modules/mi-sesion/mi-sesion.module').then(m => m.MiSesionModule), canActivate: [CanActiveViaAuthGuard] },
  { path: 'ofertas', loadChildren: () => import('./modules/categorias/categorias.module').then(m => m.CategoriasModule), canActivate: [CanActiveViaAuthGuard] },
  { path: 'aliado/:companyId/:offerId', component: LandingOfferComponent },
  { path: 'campaign', loadChildren: () => import('./pages/campaign/layout/layout.module').then(m => m.LayoutModule), pathMatch: 'full', canActivate: [CanActiveViaAuthGuard] },

  // Rutas principales
  { path: 'preguntas-frecuentes', component: FaqComponent },
  { path: 'blog', component: EducationBlogComponent },
  { path: 'blog/:post', component: EducationBlogPostComponent },
  { path: 'error', component: ErrorPageComponent, pathMatch: 'full' },
  // { path: 'dev-tools/list-offers', component: ListOfferComponent, canActivate:[DevToolsGuardGuard] },
  // { path: 'dev-tools', loadChildren: () => import('./modules/dev-tools/dev-tools.module').then(m => m.DevToolsModule), canActivate: [DevToolsGuardGuard] },
  // { path: 'sitemap', component: SitemapComponent },
  { path: '**', redirectTo: 'error', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64],
    onSameUrlNavigation: 'reload',
    enableTracing: false
    // useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
