import { Injectable } from '@angular/core';
declare var dataLayer: any;

@Injectable({
  providedIn: 'root'
})
export class DataLayersService {

  constructor() { }

  /**
   * EVENTO GA4 user
   */
  dl_userId( id_usuario: string | null, tipo_usuario: string,){
    dataLayer.push({
      'event':'ga_event',
      'name_event':'user',
      'user_id': id_usuario, 
      'user_tye': tipo_usuario
    });
  }

  /**
   * EVENTO GA4 - Parámetro: menu_option
   */
  dl_eventoGA4_menu( name_event: string, texto: string ){
    dataLayer.push({
      'event': 'ga_event',
      'name_event': name_event,
      'menu_option': texto,
    });
  }

  /**
   * EVENTO GA4 de 1 parámetro
   * @param name_event: Nombre de evento.
   */
  dl_eventoGA4_general( name_event:string ){
    dataLayer.push({
      'event': 'ga_event',
      'name_event' : name_event
    });
  }

  /**
   * EVENTO GA4 - search
   */
  dl_eventoGA4_search( busqueda: string, tipo_credito: string | null, digital: boolean | null, monto: string | null, termino: string | null, tipo_lugar: string | null ){
    dataLayer.push({
      'event': 'ga_event',
      'name_event' : 'search',
      'search_term': busqueda,
      'filter_typecredit': tipo_credito,
      'filter_digital': digital,
      'filter_amount': monto,
      'filter_term': termino,
      'filter_typeplace': tipo_lugar
    });
  }

  /**
   * EVENTO GA4 - Este evento envia la data para 3 tags diferentes (select_content, send_feedback, continue_application)
   */
  dl_eventoGA4_content( nombre_evento: string, tipo_producto: string, id_oferta: string, marca:string ){
    dataLayer.push({
      'event': 'ga_event',
      'name_event' : nombre_evento,
      'content_type': tipo_producto,
      'content_id': id_oferta,
      'brand_offer': marca
    });
  }

  /**
   * EVENTO GA4 - confirm_application
   */
  dl_eventoGA4_confirm_application( tipo_producto: string | null, id_oferta: string | null, marca:string | null, texto:string ){
    dataLayer.push({
      'event': 'ga_event',
      'name_event' : 'confirm_application',
      'content_type': tipo_producto,
      'content_id': id_oferta,
      'brand_offer': marca,
      'button_name': texto
    });
  }

  dl_eventoGA4_view_blog(name_event:string,blog_name:string,date : string){
    dataLayer.push({
      'event': 'ga_event',
      'name_event' : name_event,
      'blog_name': blog_name,
      'date': date,
    });
  }

}
