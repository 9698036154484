<div class="head-blog">
    <div class="content-title-blog">
        <h1>
            {{postView?.title}}
        </h1>
        <div class="copy">
            <img src="assets/icons/icono_mi.svg" />
            <p>Por: Buscacrédito. {{postView?.date}}</p>
        </div>
    </div>
    <div class="content-img">
        <img src="assets/icons/blog_icon_bc.png" alt="icono-blog" title="icono-blog" />
    </div>
</div>
<div class="content-post">
    <div id="content" [innerHTML]="postView?.content">

    </div>
    <div class="links-posts">
        <div class="btn-link-post" *ngIf="postBefore != undefined">
            <button (click)="goPost(postBefore)" ><i class="fa fa-chevron-left"></i></button>
            <div>
                <p>Anterior</p>
                <a (click)="goPost(postBefore)">{{postBefore?.title}}</a>
            </div>
        </div>
        <div class="btn-link-post right" *ngIf="postAfter != undefined">
            <div>
                <p>Siguiente</p>
                <a (click)="goPost(postAfter)">{{postAfter?.title}}</a>
            </div>
            <button (click)="goPost(postAfter)"><i class="fa fa-chevron-right"></i></button>
        </div>
    </div>
</div>