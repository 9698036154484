import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, AfterViewInit {

    public tipsFinancieros = [
        {
            "id": 1,
            "texto": "Estar siempre al día en tus obligaciones ayudará a construir unas finanzas sólidas y saludables."
        },
        {
            "id": 2,
            "texto": "Buscacrédito muestra opciones de productos financieros ajustados a tu realidad financiera y a tus necesidades."
        },
        {
            "id": 3,
            "texto": "Tienes el respaldo y la seguridad de una gran marca como Datacrédito Experian."
        },
        {
            "id": 4,
            "texto": "Dentro del Marketplace encuentras ofertas ideales para ti de forma gratis y segura."
        },
        {
            "id": 5,
            "texto": "Las aplicaciones a ofertas de crédito en el marketplace, no generarán huellas de consulta en tú historia de crédito."
        },
        {
            "id": 6,
            "texto": "La aprobación final de las ofertas depende de nuestros aliados."
        },
        {
            "id": 7,
            "texto": "Tu capacidad de endeudamiento no debe superar el 30% de tus ingresos."
        },
        {
            "id": 8,
            "texto": "Consulta tu historia de crédito periódicamente, recuerda que puedes hacerlo gratis en midatacrédito."
        },
        {
            "id": 9,
            "texto": "93% de las obligaciones reportadas corresponden a datos positivos."
        },
        {
            "id": 10,
            "texto": "Estar reportado en una central de riesgo mejora las oportunidades del acceso al crédito."
        },
        {
            "id": 11,
            "texto": "A mayor experiencia se espera que los créditos sean usados principalmente para construcción de patrimonio, y en menor medida para los gastos del día a día."
        },
        {
            "id": 12,
            "texto": "Se considera que una persona tiene un portafolio sólido y diverso cuando tiene 4 o más obligaciones abiertas y al día."
        },
        {
            "id": 13,
            "texto": "Ser codeudor tendrá un impacto en el cálculo de su capacidad de endeudamiento y es un riesgo adicional ya que el pago de la deuda no depende de ti."
        },
        {
            "id": 14,
            "texto": "Estar siempre al día en tus obligaciones ayudará a construir unas finanzas sólidas y saludables."
        }

    ];
    public tipFinanciero = "";
    @Input() isModal = true;
    @Input() height = '100%';
    @ViewChild("contentLoader") contentLoader!: ElementRef;

    constructor() {
        this.obtenerTip();
    }
    ngAfterViewInit(): void {
        // console.log(this.contentLoader?.nativeElement)
        if (!this.isModal) {
            setTimeout(() => {
                this.getHeightParent();
            }, 100);
        }
    }

    ngOnInit() {
    }


    obtenerTip() {
        let minimo = 1;
        let maximo = this.tipsFinancieros.length - 1;
        let numRandom = Math.floor(Math.random() * (maximo - minimo + minimo)) + minimo;
        this.tipFinanciero = this.tipsFinancieros[numRandom].texto;
    }


    getHeightParent() {
        let content = this.contentLoader?.nativeElement;
        let parentContent = content?.parentElement?.parentElement;
        let heightParent = 0;
        let paddingTop = '0px';
        let paddingBottom = '0px';
        let borderRadius= '0px'
        if (parentContent != null) {
            heightParent = parentContent.offsetHeight;
            paddingTop = getComputedStyle(parentContent).getPropertyValue('padding-top');
            paddingBottom = getComputedStyle(parentContent).getPropertyValue('padding-bottom');
            borderRadius = getComputedStyle(parentContent).getPropertyValue('border-radius');
        }
        if (content != null) {
            content.style.height = (heightParent - parseInt(paddingBottom.split('px')[0]) - parseInt(paddingTop.split('px')[0])) + 'px';
            content.style.borderRadius = borderRadius;
        }
    }


}
