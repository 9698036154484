import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BrokerService } from './broker.service';
import { SesionService } from './sesion.service';
import { ModalService } from './modal.service';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { DataLayersService } from './data-layers.service';
import { UtilitiesService } from './utilities.service';

declare var $: any;

@Injectable()
export class CanActiveViaAuthGuard implements CanActivate {
	loggin: any;
	cookie: any;
	userData: any;
	infoComplete: any;
	phone: any;
	ecs: any;

	constructor(
		private router: Router,
		public sesion: SesionService,
		private broker: BrokerService,
		private cookieservice: CookieService,
		public modal: ModalService,
		private dlService: DataLayersService,
		private utilities: UtilitiesService) {
		this.infoComplete = this.sesion.infoComplete;
	}
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
		const page = route.url.toString();
		const params = route.queryParams;
		this.sesion.path = page;
		this.sesion.header = 1;

		const cookie = this.cookieservice.get('IDSESSIONMDC');
		const data = {
			clientId: environment.clientId,
			idSession: this.cookieservice.get('IDSESSIONMDC'),
			country: environment.country
		};

		if (this.sesion.sesionCookie) {
			this.dlService.dl_userId('.', 'logueado');
		}
		if (!this.sesion.sesionCookie) {
			this.dlService.dl_userId(null, 'anonimo');
		}
		// let currentUtmCampaign = localStorage.getItem('utm_campaign');
		// if (currentUtmCampaign != undefined && currentUtmCampaign != params['utm_campaign']) {
		// 	localStorage.setItem('utm_campaign', params['utm_campaign']);
		// }
		if (state.url == '/perfil-bc/financial-info') {

			this.sesion.header = 0;
		}
		if ((page === 'campaign,bosi' || page === 'campaign') && !this.sesion.sesionCookie) {
			return true;
		}
		if (page === 'campaign,estado' && !this.sesion.sesionCookie) {
			return true;
		}

		if (!this.sesion.sesionCookie
			&& (page === 'error'
				|| page === 'ofertas'
				|| page === 'perfil-bc/financial-info'
				|| page === 'detalle'
				|| page === 'detalle/estado'
				|| page === 'perfil-bc/mis-aplicaciones')) {
			this.router.navigate(['/']);

			return false;
		}

		if (page === '' && this.sesion.sesionCookie) {
			// this.validateSession();
			return this.broker.validar_sesion(data).pipe(map((result: any) => {
				this.utilities.getUtmParams(false);
				this.sesion.isSessionValid = true;
				this.sesion.sesionCookie = this.cookieservice.get('IDSESSIONMDC');
				this.sesion.nameSession = result.nombre;
				this.phone = result.phone;
				this.sesion.dataUser = result;
				this.sesion.userPhone = result.phone;
				//this.modalTerms()
				this.broker.getIncomeInfo().subscribe((result: any) => {
					this.ecs = result;
					// if(this.ecs.params[2].value == false) {
					// 	setTimeout(() => {
					// 		this.modal.termsModal();
					// 		$(".modal-backdrop").addClass("show");
					// 	}, 2000);					
					// }     
					if (result.params.length > 1) {
						localStorage.setItem('userPhone', result.params[1].value);
					}
					if (result.params[0].value != "true") {
						
						// if (localStorage.getItem('utm_campaign') != undefined) {
						// 	this.router.navigate(['/ofertas'], { queryParams: { utm_campaign: localStorage.getItem('utm_campaign') } });
						// }
						// else {
							if (localStorage.getItem('url')) {
								this.router.navigate(['/ofertas' + localStorage.getItem('url')]);
							} else {
								if (localStorage.getItem('landingByOffer') == "1") {
									this.router.navigate(['/aliado/' + localStorage.getItem('companyId') + "/" + localStorage.getItem('offerId')]);
								}else{
									this.router.navigate(['/ofertas']);
								}
							}
						// }
					} else {
						// if (localStorage.getItem('utm_campaign') != undefined) {
						// 	this.router.navigate(['/perfil-bc/financial-info'], { queryParams: { utm_campaign: localStorage.getItem('utm_campaign') } });
						// }
						// else {
							this.router.navigate(['/perfil-bc/financial-info']);
						// }
					}
					return true;
				},
					(error: any) => {
						this.cookieservice.delete('IDSESSIONMDC');
						this.router.navigate(['/']);
						this.sesion.sesionCookie = null;
						return false;
					}
				)
			}),
				catchError((error) => {
					this.broker.cerrar_sesion(data).subscribe((response: any) => {
					}, error => {
					});
					setTimeout(() => {
						this.cookieservice.delete('IDSESSIONMDC');
						this.cookieservice.delete('IDSESSIONMDC', cookie, environment.domain);
						this.router.navigate(['/']);
						this.sesion.sesionCookie = null;
					}, 200);
					return of(false);
				})
			);
			// return false;
		}
		if (page === 'home' && this.sesion.sesionCookie) {
			return this.broker.validar_sesion(data).pipe(
				map((result: any) => {
					this.sesion.isSessionValid = true;
					this.sesion.sesionCookie = this.cookieservice.get('IDSESSIONMDC');
					this.sesion.nameSession = result.nombre;
					this.sesion.dataUser = result;
					this.sesion.userPhone = result.phone;
					this.broker.getIncomeInfo().subscribe((result: any) => {
						if (result.params.length > 1) {
							localStorage.setItem('userPhone', result.params[1].value);
						}
						// if (result.params[0].value != "true") {
						//   if (localStorage.getItem('utm_campaign') != undefined) {
						//     this.router.navigate(['/ofertas'], { queryParams: { utm_campaign: localStorage.getItem('utm_campaign') } });
						//   }
						//   else {
						//     this.router.navigate(['/ofertas']);
						//   }
						// } else {
						//   if (localStorage.getItem('utm_campaign') != undefined) {
						//     this.router.navigate(['/financial-info'], { queryParams: { utm_campaign: localStorage.getItem('utm_campaign') } });
						//   }
						//   else {
						//     this.router.navigate(['/financial-info']);
						//   }
						// }
					},
						(error: any) => {
							this.cookieservice.delete('IDSESSIONMDC');
							this.router.navigate(['/']);
							this.sesion.sesionCookie = null;
						}
					)
					return true
				}),
				catchError((error) => {
					this.broker.cerrar_sesion(data).subscribe((response: any) => {
					}, error => { });
					setTimeout(() => {
						this.cookieservice.delete('IDSESSIONMDC');
						this.cookieservice.delete('IDSESSIONMDC', cookie, environment.domain);
						this.router.navigate(['/']);
						this.sesion.sesionCookie = null;
					}, 200);
					return of(false);
				})
			);
		}
		if (page != '' && this.sesion.sesionCookie) {
			// this.validateSession();
			return this.broker.validar_sesion(data).pipe(
				map((result: any) => {
					this.sesion.isSessionValid = true;
					this.sesion.sesionCookie = this.cookieservice.get('IDSESSIONMDC');
					this.sesion.nameSession = result.nombre;
					this.sesion.userPhone = result.phone;
					this.broker.getIncomeInfo().subscribe((result: any) => {
						this.ecs = result;
						// if(this.ecs.params[2].value == false) {
						// 	setTimeout(() => {
						// 		this.modal.termsModal();
						// 		$(".modal-backdrop").addClass("show");
						// 	}, 2000);					
						// }
						return true;
					},
						(error: any) => {
							this.cookieservice.delete('IDSESSIONMDC');
							this.router.navigate(['/']);
							this.sesion.sesionCookie = null;
							return false;
						}
					)
					return true
				}),
				catchError((error) => {
					this.broker.cerrar_sesion(data).subscribe((response: any) => {
					}, error => { });
					setTimeout(() => {
						this.cookieservice.delete('IDSESSIONMDC');
						this.cookieservice.delete('IDSESSIONMDC', cookie, environment.domain);
						this.router.navigate(['/']);
						this.sesion.sesionCookie = null;
					}, 200);
					return of(false);
				})
			);
		}
		return true;
	}

	validateSession() {
		const cookie = this.cookieservice.get('IDSESSIONMDC');
		const data = {
			clientId: environment.clientId,
			idSession: this.cookieservice.get('IDSESSIONMDC'),
			country: environment.country
		};

		this.broker.validar_sesion(data).subscribe((response: any) => {
			this.sesion.sesionCookie = this.cookieservice.get('IDSESSIONMDC');
			this.sesion.nameSession = response.nombre;
			this.sesion.dataUser = response;
			this.sesion.userPhone = response.phone;
		},
			(error: any) => {
				this.broker.cerrar_sesion(data).subscribe((response: any) => {
				}, error => {
				});
				setTimeout(() => {
					this.cookieservice.delete('IDSESSIONMDC');
					this.cookieservice.delete('IDSESSIONMDC', cookie, environment.domain);
					this.router.navigate(['/']);
					this.sesion.sesionCookie = null;
				}, 200);
			}
		);
	}
}
