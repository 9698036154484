import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { EducationBlogService } from 'src/app/core/services/education-blog.service';
import { BrokerService } from 'src/app/core/services/broker.service';
import { UtilitiesService } from 'src/app/core/services/utilities.service';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { SesionService } from 'src/app/core/services/sesion.service';

@Component({
  selector: 'app-education-blog-post',
  templateUrl: './education-blog-post.component.html',
  styleUrls: ['./education-blog-post.component.scss']
})
export class EducationBlogPostComponent implements OnInit {

  public postView: any;
  public url: any = "";
  public trustedHtml: any;
  public postAfter: any;
  public postBefore: any;

  constructor(private activateRoute: ActivatedRoute, private blogService: EducationBlogService, public broker: BrokerService, private utilities: UtilitiesService, public router: Router, public dataLayer: DataLayersService, public sesion: SesionService, private _title: Title, private _metaService: Meta) { }

  ngOnInit() {
    this.url = this.activateRoute.snapshot.paramMap.get('post')?.toString();
    if (this.sesion.sesionCookie) {
      this.dataLayer.dl_userId('.', 'logueado');
    }
    if (!this.sesion.sesionCookie) {
      this.dataLayer.dl_userId(null, 'anonimo');
    }  
    if (this.blogService.posts.length == 0) {
      this.broker.getContentBlog().subscribe({
        next: (res: any) => {
          this.blogService.posts = res;
          this.setPost();
          this.getOtherPosts();
        }
      })
    } else {
      this.setPost();
      this.getOtherPosts();
    }
  }

  /**
   * Funcion que busca el post que debe cargar por url
   */
  setPost() {
    this.postView = this.blogService.posts.find((ele: any) => { return ele.url == this.url });
    this.utilities.setStyleByCode(this.blogService.cssPost);
    this.setMetaData(this.postView)
  }


  /**
   * Funcion que trae el post anterior y el post siguiente
   */
  getOtherPosts() {
    if (this.blogService.posts.length > 1) {
      let index = this.blogService.posts.findIndex((ele: any) => { return ele.url == this.url });
      // console.log(index);
      if (this.blogService.posts[index - 1] == undefined) {
        this.postBefore = this.blogService.posts[this.blogService.posts.length - 1];
      } else {
        this.postBefore = this.blogService.posts[index - 1];
      }
      if (this.blogService.posts[index + 1] == undefined) {
        this.postAfter = this.blogService.posts[0];
      } else {
        this.postAfter = this.blogService.posts[index + 1];
      }
    }
  }

  /**
   * Funcion para cambiar los metadatos
   */
  setMetaData(post: any){
    this._title.setTitle(post.metaTitle);
    this._metaService.updateTag({ name: 'description', content: post.metaDesc });
    this._metaService.updateTag({ name: 'keywords', content: post.keywords });
  }

  /**
   * Funcion para redireccionar a los post
   */
  goPost(post: any) {
    this.dataLayer.dl_eventoGA4_view_blog('clic_change_blog',post.title,new Date().toLocaleDateString());
    window.location.href = '/blog/'+post.url;
  }

}
