<!-- <div class="container"> -->
<div class="head-blog">
    <h1>
        Encuentra artículos y herramientas en nuestro blog, para que puedas tomar las mejores decisiones financieras.
    </h1>
    <div class="content-img">
        <img src="assets/icons/blog_icon_bc.png" alt="icono-blog" title="icono-blog"/>
    </div>
</div>
<div class="menu-blog">
    <div class="card-blog" *ngFor="let post of blogService.posts">
        <div class="img-card-blog">
            <img [src]="post.imgPreview" [alt]="post.title" [title]="post.title"/>
        </div>
        <div class="info-card-blog">
            <p class="date-card-blog">{{post.date}}</p>
            <p class="text-card-blog">{{post.title}}</p>
            <button (click)="goPost(post)" >Leer este artículo</button>
        </div>
    </div>
    <!-- <div class="card-blog">
        <div class="img-card-blog">
            <img src="assets/images/imagen_entrada_blog.png" alt="entrada" title="entrada"/>
        </div>
        <div class="info-card-blog">
            <p class="date-card-blog">fecha</p>
            <p class="text-card-blog">Tasa Fija vs. Tasa variable en préstamos financieros</p>
            <button>Leer este artículo</button>
        </div>
    </div>
    <div class="card-blog">
        <div class="img-card-blog">
            <img src="assets/images/imagen_entrada_blog.png" alt="entrada" title="entrada"/>
        </div>
        <div class="info-card-blog">
            <p class="date-card-blog">fecha</p>
            <p class="text-card-blog">Tasa Fija vs. Tasa variable en préstamos financieros</p>
            <button>Leer este artículo</button>
        </div>
    </div>
    <div class="card-blog">
        <div class="img-card-blog">
            <img src="assets/images/imagen_entrada_blog.png" alt="entrada" title="entrada"/>
        </div>
        <div class="info-card-blog">
            <p class="date-card-blog">fecha</p>
            <p class="text-card-blog">Tasa Fija vs. Tasa variable en préstamos financieros</p>
            <button>Leer este artículo</button>
        </div>
    </div> -->
</div>
<!-- </div> -->