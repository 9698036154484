<div class="container-fluid footer">
  <div class="container">
    <div class="row logo">
      <div class="col-10 mx-auto col-sm-4 pb-1 pb-md-4 pt-1 pt-md-3 pr-5  d-none d-md-block">
        <img class="icon" src="assets/icons/new/logo_negativo_n.svg"
          alt="Score puntaje credito midatacredito buscacredito" title="Buscacrédito de Midatacrédito" appLazyLoadImg />
      </div>
      <div class="mapsite col-12 col-sm-8 mb-3 mb-md-1">
        <ul class="row list-group list-group-flush d-sm-none">
          <li class="list-group-item">
            <button class="btn btn-link" data-toggle="collapse" data-target="#midatacash" aria-expanded="false"
              aria-controls="midatacash">
              {{sesion.datos.footer?.seccion1?.titulo}}
            </button>
            <div id="midatacash" class="collapse">
              <a href="{{politica_privacidad}}" class="mapsite_link" title="Política de privacidad"
                (click)="clic_footer_menu('Política de privacidad')">
                {{sesion.datos.footer?.seccion1?.textos?.txt2}}</a>
              <a href="{{tyc}}" class="mapsite_link" title="Términos y condiciones"
                (click)="clic_footer_menu('Términos y condiciones')">
                {{sesion.datos.footer?.seccion1?.textos?.txt3}}</a>
            </div>
          </li>
          <li class="list-group-item">
            <button class="btn btn-link" data-toggle="collapse" data-target="#faqfo" aria-expanded="false"
              aria-controls="faqfo">
              {{sesion.datos.footer?.seccion2?.titulo}}
            </button>
            <div id="faqfo" class="collapse">
              <a routerLink="/preguntas-frecuentes" class="mapsite_link"
                title="Preguntas frecuentes" (click)="clic_footer_menu('Preguntas frecuentes')">
                {{sesion.datos.footer?.seccion2?.textos?.txt1}}</a>
              <a href="{{sesion.datos.footer?.seccion2?.links?.url2}}" class="mapsite_link"
                title="Contáctenos" (click)="clic_footer_menu('Contáctenos')">
                {{sesion.datos.footer?.seccion2?.textos?.txt2}}</a>
              <a href="{{sesion.datos.footer?.seccion2?.links?.url3}}" class="mapsite_link" title="SIC"
                (click)="clic_footer_menu('Superintendencia de Industria y Comercio - SIC')">
                {{sesion.datos.footer?.seccion2?.textos?.txt3}}</a>
              <a href="{{sesion.datos.footer?.seccion2?.links?.url4}}" class="mapsite_link"
                title="Canales de consulta" (click)="clic_footer_menu('Canales gratuitos de consulta')">
                {{sesion.datos.footer?.seccion2?.textos?.txt4}}</a>
            </div>
          </li>
          <!-- <li class="list-group-item">
              <button class="btn btn-link" data-toggle="collapse" data-target="#somos" aria-expanded="false" aria-controls="somos">
                  Otras soluciones
              </button>
              <div id="somos" class="collapse">
                <a href="" class="mapsite_link">Historial de crédito</a>
                <a href="" class="mapsite_link">Mi data al día</a>
                <a href="" class="mapsite_link">C2C</a>
                <a href="" class="mapsite_link">Antifraude</a>
              </div>
          </li> -->
        </ul>
        <div class="footer-full d-none d-sm-block">
          <div class="row justify-content-around ">
            <div class="col-sm-4">
              <div class="title">{{sesion.datos.footer?.seccion1?.titulo}}</div>
              <a href="{{politica_privacidad}}" class="mapsite_link" title="Política de privacidad"
                (click)="clic_footer_menu('Política de privacidad')">
                {{sesion.datos.footer?.seccion1?.textos?.txt2}}
              </a>
              <a href="{{tyc}}" class="mapsite_link" title="Términos y condiciones"
                (click)="clic_footer_menu('Términos y condiciones')">
                {{sesion.datos.footer?.seccion1?.textos?.txt3}}</a>
            </div>
            <div class="col-sm-4">
              <div class="title">{{sesion.datos.footer?.seccion2?.titulo}}</div>
              <a routerLink="/preguntas-frecuentes" class="mapsite_link"
                title="Preguntas frecuentes" (click)="clic_footer_menu('Preguntas frecuentes')">
                {{sesion.datos.footer?.seccion2?.textos?.txt1}}</a>
              <a href="{{sesion.datos.footer?.seccion2?.links?.url2}}" class="mapsite_link"
                title="Contáctenos" (click)="clic_footer_menu('Contáctenos')">
                {{sesion.datos.footer?.seccion2?.textos?.txt2}}</a>
              <a href="{{sesion.datos.footer?.seccion2?.links?.url3}}" class="mapsite_link" title="SIC"
                (click)="clic_footer_menu('Superintendencia de Industria y Comercio - SIC')">
                {{sesion.datos.footer?.seccion2?.textos?.txt3}}</a>
              <a href="{{sesion.datos.footer?.seccion2?.links?.url4}}" class="mapsite_link"
                title="Canales de consulta" (click)="clic_footer_menu('Canales gratuitos de consulta')">
                {{sesion.datos.footer?.seccion2?.textos?.txt4}}</a>
            </div>
            <!-- <div class="col-sm-4">
              <div class="title">Otras soluciones</div>
              <a href="" class="mapsite_link">Historial de crédito</a>
              <a href="" class="mapsite_link">Mi data al día</a>
              <a href="" class="mapsite_link">C2C</a>
              <a href="" class="mapsite_link">Antifraude</a>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="cont-copy">
      <div class="copyright">
        <img src="assets/icons/Logo-Datacredito.svg" alt="DataCrédito Experian" appLazyLoadImg>
      </div>
      <div class="sic">
        <p>VIGILADO</p>
        <img src="assets/icons/v2/logo_SIC_blanco.webp" alt="Superintendencia de industria y comercio" appLazyLoadImg>
      </div>
      <p class="version">
        V.a13-1.18
      </p>
    </div>
    <p class="pBottom">{{anio}} Experian Colombia S.A. © Derechos reservados. NIT 900.422.614-8. Carrera 7 No. 76 – 35
      Bogotá D.C., Colombia.
      Email: <a href="mailto:servicioalciudadano@experian.com?subject=Servicio al ciudadano - Buscacrédito">servicioalciudadano@experian.com</a>
    </p>
  </div>
</div>