import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common'

@Injectable({
  providedIn: 'root'
})
export class MetatagsService {

	constructor(@Inject(DOCUMENT) private dom:any) {}

	createCanonicaURL( url:string ) {
		// let canURL = url == undefined ? this.dom.URL : url;
		const head = this.dom.getElementsByTagName('head')[0];
		let element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
		if (element==null) {
			element= this.dom.createElement('link') as HTMLLinkElement;
			head.appendChild(element);
		}
		element.setAttribute( 'rel','canonical' );
		element.setAttribute( 'href',url );
	}

}
