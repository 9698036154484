import { Component, OnInit } from '@angular/core';
import { LoadService } from 'src/app/core/services/load.service';

@Component({
  selector: 'app-micro-loader',
  templateUrl: './micro-loader.component.html',
  styleUrls: ['./micro-loader.component.scss']
})
export class MicroLoaderComponent implements OnInit {

  constructor(public load: LoadService) { }

  ngOnInit() {
  }

}
