<div class="header fixed-top scrolled" [ngClass]="{'secundary': !scroll, 'no-color' : noColor}"
  *ngIf="sesion.header == 1">
  <nav class="navbar navbar-expand-md container-fluid">
    <div *ngIf="dataLogHea.logo == 1" class="logo">
      <a *ngIf="sesion.path == 'campaign' || sesion.path == 'campaign,estado'" class="navbar-brand"
        title="Logo Midatacrédito">
        <img class="icon" src="assets/icons/v2/logo_midc_white.svg" alt="Logo Midatacrédito" title="Logo Midatacrédito">
      </a>
      <a *ngIf="sesion.path != 'campaign' && sesion.path != 'campaign,estado'" class="navbar-brand"
        (click)="direccionar_logo()" title="Logo Midatacrédito">
        <img class="icon" src="assets/icons/v2/logo_midc_white.svg" alt="Logo Midatacrédito"
          title="Logo Midatacrédito" />
      </a>
    </div>
    <div *ngIf="dataLogHea.logo == undefined || dataLogHea.logo != 1" class="logo">
      <a *ngIf="sesion.path == 'campaign' || sesion.path == 'campaign,estado' " class="navbar-brand"
        title="Logo Midatacrédito">
        <img class="icon d-none d-md-block" src="assets/icons/new/logo_negativo_n.svg" alt="midatacredito datacredito ofertas de credito"
          title="Logo Midatacrédito" />
          <img class="icon d-block d-md-none head-mobile" src="assets/icons/v2/logo_midc_white.svg" alt="midatacredito datacredito ofertas de credito"
          title="Logo Midatacrédito" />
      </a>
      <a *ngIf="sesion.path != 'campaign' && sesion.path != 'campaign,estado' " class="navbar-brand"
      (click)="direccionar_logo()" title="Logo Midatacrédito">
        <img class="icon d-none d-md-block" src="assets/icons/new/logo_negativo_n.svg" alt="midatacredito datacredito ofertas de credito"
          title="Logo Midatacrédito" />
          <img *ngIf="!sesion.sesionCookie" class="icon d-block d-md-none head-mobile" src="assets/icons/v2/logo_midc_white.svg" alt="midatacredito datacredito ofertas de credito"
          title="Logo Midatacrédito" />
          <img  *ngIf="sesion.sesionCookie"class="icon d-block d-md-none" src="assets/icons/new/logo_negativo_n.svg" alt="midatacredito datacredito ofertas de credito"
          title="Logo Midatacrédito" />
      </a>
    </div>

    <div class="collapse navbar-collapse">
      <ul class="navbar-nav align-center">
        <div *ngFor="let cat of categorias; let i = index " class="{{cat.url}}">
          <li class="nav-item {{cat.url}}" *ngIf="cat.state == 1">
            <div class="btn-group dropCategory {{cat.url}}">
              <button *ngIf="cat.subcategories != 0" type="button" class="nav-link dropdown-toggle {{cat.url}}"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="{{cat.url}}"
                (click)="direccionar(cat.url)">
                {{cat.name}}
              </button>
              <button *ngIf="cat.subcategories == 0" type="button" class="nav-link dropdown-toggle {{cat.url}}"
                id="{{cat.url}}" (click)="direccionar(cat.url)">
                {{cat.name}}
              </button>
              <div class="dropdown-menu iDropCategory" [ngStyle]="{'margin-left': styleDrop[i]}"
                *ngIf="cat.subcategories != 0">
                <div class="contUp" (click)="direccionar(cat.url)">
                  <i class="arrowUp"></i>
                </div>
                <ul [ngStyle]="{'column-count': styleCols[i]}">
                  <div *ngFor="let sub of cat.subcategories" class="{{sub.url}}">
                    <li *ngIf="sub.state == 1" (click)="direccionar(cat.url+'/'+sub.url)" id="{{sub.url}}"
                      class="{{sub.url}}"><img src="{{sub.iconD}}" alt="{{sub.name}}" appLazyLoadImg
                        class="iconCategory {{sub.url}}" /><span class="{{sub.url}}">{{sub.name}}</span></li>
                  </div>
                </ul>
              </div>
            </div>
          </li>
        </div>
        <li class="nav-item border-left">
          <a class="nav-link btn-mrkt btn-outline-white border-r-18 p-light" title="Registro Buscacrédito"
            (click)="goBlog()">
            <span>Educación</span>
            <!-- <i class="fa fa-chevron-right" aria-hidden="true"></i> -->
          </a>
        </li>
        <li class="nav-item"
        *ngIf="!sesion.sesionCookie && (sesion.path != 'campaign' && sesion.path != 'campaign,estado' )">
          <a class="nav-link btn-mrkt btn-outline-white border-r-18 bgc-green p-light" title="Registro Buscacrédito"
            (click)="redirectLogin()">
            <span>Ingresar </span>
            <!-- <i class="fa fa-chevron-right" aria-hidden="true"></i> -->
          </a>
        </li>
        <li class="nav-item dropdown mr-4"
          *ngIf="sesion.sesionCookie && initial && (sesion.path != 'campaign' && sesion.path != 'campaign,estado')">
          <a class="nav-link dropdown-toggle btn-mrkt btn-outline-white border-r-18 p-light" href="#" title="Nombre usuario"
            id="necesitas" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            (click)="envio_datalayer_header('Menú usuario | Buscacrédito')">
            <span>Hola, {{this.nameSesion}}</span>
            <i class="fa fa-chevron-down" aria-hidden="true"></i>
          </a>
          <div class="dropdown-menu" aria-labelledby="necesitas">
            <a class="dropdown-item" routerLink="/perfil-bc/mis-aplicaciones" title="Mis aplicaciones"
              (click)="envio_datalayer_header('Mis aplicaciones | Menú usuario')">
              <i class="fa fa-bell-o" aria-hidden="true"></i>
              <span>Mis aplicaciones</span>
            </a>
            <a class="dropdown-item" href="{{profileUrl}}" rel="noopener" title="Perfil Buscacrédito"
              (click)="envio_datalayer_header('Perfil | Menú usuario')">
              <i class="fa fa-user-o" aria-hidden="true"></i>
              <span>Perfil</span>
            </a>
            <a class="dropdown-item" (click)="cerrarSesion()" title="Cerrar sesión">
              <i class="fa fa-sign-out" aria-hidden="true"></i>
              <span>Salir</span>
            </a>
          </div>
        </li>
      </ul>
    </div>
    <!-- Collapse button -->
     <div style="display: flex;">
       <button *ngIf="!sesion.sesionCookie" class="btn-login-mobile d-block d-md-none" (click)="redirectLogin(true)" >Ingresar</button>
       <button *ngIf="sesion.path != 'campaign' && sesion.path != 'campaign,estado' " class="navbar-toggler first-button"
         (click)="toggleClass()" type="button" data-toggle="collapse" data-target="#navbarSupportedContent20"
         aria-controls="navbarSupportedContent20" aria-expanded="false" aria-label="Toggle navigation">
         <div [class.open]="isOpen" class="animated-icon">
           <span></span>
           <span></span>
           <span></span>
         </div>
       </button>
     </div>
  </nav>
</div>
<div class="submenu position-fixed" *ngIf="isOpen">
  <div class="submenu-top">
    <div class="submenu-logo">
      <div class="row">
        <a class="brand-mob" title="Logo Midatacrédito">
          <img *ngIf="!sesion.sesionCookie" src="assets/icons/v2/logo_midc_white.svg" class="icon"
            alt="midatacredito datacredito ofertas de credito" title="Logo Midatacrédito" />
            <img *ngIf="sesion.sesionCookie" src="assets/icons/new/logo_negativo_n.svg" class="icon big-log"
            alt="midatacredito datacredito ofertas de credito" title="Logo Midatacrédito" />
        </a>
        <div class="cta-close">
          <button class="close" aria-label="Close" (click)="toggleClass()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    </div>
    <div class="list-group list-group-flush">
      <!-- sesion -->
      <!-- no sesion-->
      <div *ngIf="!sesion.sesionCookie">
        <a class="list-group-item bgc-green" (click)="redirectLogin()" title="Registrate">Ingresar<i
            class="fa fa-chevron-right"></i></a>
      </div>

      <div *ngIf="sesion.sesionCookie">
        <a class="list-group-item no-color" routerLink="/perfil-bc/mis-aplicaciones" title="Mis aplicaciones"
          (click)="toggleClass()"><i class="fa fa-search" aria-hidden="true"></i>Mis aplicaciones</a>
        <a class="list-group-item no-color" href="{{profileUrl}}" rel="noopener" title="Perfil" (click)="toggleClass()">
          <i class="fa fa-user-o" aria-hidden="true"></i>Perfil</a>
      </div>
      <div>
        <a class="list-group-item reverse-color" (click)="goBlog()" (click)="toggleClass();" title="Educacion">Educación<i
            class="fa fa-chevron-right"></i></a>
      </div>
      <div class="category" id="category">

        <ul>
          <hr class="separador">
          <div *ngFor="let cat of categorias">
            <li class="" *ngIf="cat.state == 1">
              <button *ngIf="cat.subcategories != 0" class="btn btn-link {{cat.url}}_m" data-toggle="collapse"
                attr.data-target="#{{cat.name}}" aria-expanded="false" attr.aria-controls="{{cat.name}}">
                {{cat.name}}
              </button>
              <button *ngIf="cat.subcategories == 0" class="btn btn-link {{cat.url}}_m" (click)="direccionar(cat.url)"
                (click)="toggleClass()">
                {{cat.name}}
              </button>
              <div id="{{cat.name}}" class="collapse no-color" data-parent="#category">
                <ul class="itemCategory">
                  <div *ngFor="let sub of cat.subcategories" class="{{sub.url}}_m">
                    <li *ngIf="sub.state == 1" (click)="direccionar(cat.url+'/'+sub.url)" (click)="toggleClass()"
                      class="{{sub.url}}_m"><img src="{{sub.iconM}}" alt="" class="iconCategory"
                        class="{{sub.url}}_m" /><span class="{{sub.url}}_m">{{sub.name}}</span></li>
                  </div>
                </ul>
              </div>
            </li>
          </div>
        </ul>
      </div>
      <a *ngIf="sesion.sesionCookie" class="list-group-item bgc-green" (click)="cerrarSesion(); toggleClass()"
        title="Salir">Salir</a>

    </div>
  </div>
</div>