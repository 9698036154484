import { Injectable } from '@angular/core';
import { BrokerService } from './broker.service';

@Injectable({
  providedIn: 'root'
})
export class EducationBlogService {

  public posts: Array<any> = [];
  public cssPost = `.content-post {
    padding: 40px 12% 45px 12%;
 }
  .content-post h2,h1 {
    font-size: 20px;
    color: #000;
    font-weight: 600;
 }
  .content-post p {
    font-size: 14px;
    font-weight: 300;
    color: #000;
 }
  .content-post p b {
    font-weight: 600;
 }
  .content-post a {
    color: #632678;
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline;
 }
  .content-post .parragraf-img {
    display: flex;
    margin-bottom: 14px;    
 }
  @media (max-width: 768px) {
    .content-post .parragraf-img {
      flex-wrap: wrap;
   }
 }
  .content-post .parragraf-img img {
    width: 50%;
    padding: 0 40px 20px 0;
    align-self: center;
 }
  @media (max-width: 768px) {
    .content-post .parragraf-img img {
      width: 100%;
      padding: 0 0px 20px 0;
   }
 }
  @media (max-width: 768px) {
    .content-post .parragraf-img .multy-parragraf {
      width: 100%;
   }
 }
  .content-post .parragraf {
    margin-bottom: 14px;
 }
.content-post .parragraf ol,ul {
  font-size: 14px;
  font-weight: 300;
  color: #000;
 }
.content-post .parragraf ol p,ul p {
  margin: 0;
 }
  .content-post .parragraf ol b,ul b {
  font-weight: 600;
 }
.content-post .parragraf ul.arrow-list {
    list-style-type: none;
}

 .content-post .parragraf ul.arrow-list li p {
    margin: 0;
    display: inline-block;
    width: 95%;
    vertical-align: top;
 }   

 .content-post .parragraf ul.arrow-list li::before {
  content: "\\27A4";
  margin-left: -12px;
  font-size: 10px;
  padding-right: 12px;
 }

  @media (max-width: 768px) {
    .content-post .parragraf ol,ul {
      margin-left: -20px;
   }
 }
 }
  `;



  constructor(private broker: BrokerService) { }


  getPosts() {
    this.broker.getContentBlog().subscribe({
      next: (res: any) => {
        this.posts = res;
      }
    })
  }

}
