<div class="head-offer">
    <button (click)="backOffers()"><i class="fa fa-angle-left"></i>
        <p>Regresar</p>
    </button>
</div>
<div class="body-offer" *ngIf="offerUnique != undefined">
    <div class="icon-pay" *ngIf="getConfigLabel().showLabel">
        <div class="box-sponsor" [ngClass]="getConfigLabel().color">
            <p>{{offerUnique.textLabel.slice(0,20)}}</p>
            <img [src]="getConfigLabel().img" title="{{offerUnique.textLabel.slice(0,20)}}"
                alt="{{offerUnique.textLabel.slice(0,20)}}" />
        </div>
    </div>
    <div class="content-offer">
        <div class="left-offer">
            <div>
                <!-- <img title="oferta" alt="imagen oferta" [src]="offerUnique.detail.offerLogoURL" /> -->
                <img src="{{offerUnique.company.entityimg}}" *ngIf="!offerUnique.detail.companyLogoFlag"
                    alt="{{offerUnique.company.name }}" title="{{offerUnique.card.productName}}" />
                <img src="{{offerUnique.detail.offerLogoURL}}" *ngIf="offerUnique.detail.companyLogoFlag"
                    alt="{{offerUnique.company.name }}" title="{{offerUnique.card.productName}}"
                    [ngClass]="{'tc':cardForTC}" />
            </div>
            <button *ngIf="selectButton() == 1" class="btn-cta" (click)="applyOffer()">Aplicar ahora<i
                    class="fa fa-angle-right"></i></button>
            <button *ngIf="selectButton() == 2 && !btn_retomar_aplicacion" class="btn-cta blue" (click)="redirect()">Retomar aplicación<i class="fa fa-angle-right"></i></button>
            <button *ngIf="selectButton() == 2 && btn_retomar_aplicacion" class="btn-cta blue spinner-btn">                         
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </button>
        </div>
        <div class="right-offer">
            <!-- <p class="text-basic big">Estás a un paso de obtener un cupo de crédito hasta por $1.000.000 desde tu
                celular o computador.</p> -->
            <p class="text-basic big">{{offerUnique.detail.title}}</p>
            <div class="main-info-offer">
                <div>
                    <p class="text-basic">Recibes:</p>
                    <p class="text-blue">{{offerUnique.card.offerValue}}</p>
                </div>
                <div>
                    <p class="text-basic">{{offerUnique.card.offerAproxValueTitle}}</p>
                    <p class="text-blue">{{offerUnique.card.offerAproxValue}}</p>
                </div>
            </div>
            <div class="add-benefit" *ngIf="offerUnique.detail.additionalBenefits">
                <img title="crown" class="crown" alt="corona-beneficio" src="assets/icons/Icono_corona_adicional.svg" />
                <div class="box-benefit">
                    <p class="descBenefit">{{offerUnique.detail.textAdditionalBenefits}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="aditional-offer">
        <div class="box-aditional" *ngIf="offerUnique.detail.benefits">
            <p class="title">{{offerUnique.detail.titleBenefits}}:</p>
            <hr />
            <div class="list">
                <div class="bullet" *ngFor="let benefit of offerUnique.detail.benefits">
                    <i class="fa fa-check" aria-hidden="true"></i>
                    <p>{{benefit}}</p>
                </div>
            </div>
        </div>
        <div class="box-aditional" *ngIf="offerUnique.detail.property">
            <p class="title">Características de la oferta:</p>
            <hr />
            <div class="list">
                <div class="bullet" *ngFor="let property of offerUnique.detail.property">
                    <i class="fa fa-check" aria-hidden="true"></i>
                    <p>{{property.value}}</p>
                </div>
            </div>
        </div>
        <div class="box-aditional" >
            <p class="title">Requisitos de la oferta:</p>
            <hr />
            <div class="list">
                <div class="bullet" *ngFor="let requirements of offerUnique.detail.requirements">
                    <i class="fa fa-check" aria-hidden="true"></i>
                    <p>{{requirements}}</p>
                </div>
                <div class="bullet">
                    <i class="fa fa-check" aria-hidden="true"></i>
                    <p>Puntaje de Datacrédito necesario: <b>{{textScore}}</b></p>
                    <div class="puntaje-colores">
                        <div class="bajo" id="{{id}}b"></div>
                        <div class="medio-bajo" id="{{id}}mb"></div>
                        <div class="medio" id="{{id}}m"></div>
                        <div class="medio-alto" id="{{id}}ma"></div>
                        <div class="alto" id="{{id}}a"></div>
                    </div>
                    <!-- <p class="link-add">¿Quieres
                        <a href="https://www.midatacredito.com" target="_blank" rel="noopener"
                            title="Consultar mi puntaje">conocer tu Puntaje</a>?
                    </p> -->
                </div>
            </div>
        </div>
    </div>
    <div class="sub-footer">
        <p>Buscacrédito y Experian no son entidades financieras, somos una plataforma en línea que facilita el acceso
            de clientes a productos y servicios ofrecidos por instituciones financieras asociadas y acreditadas en la
            plataforma. Para mayor información sobre tasas, comisiones, gastos y demás conceptos aplicables, puede
            recurrir al tarifario de la entidad financiera ubicado en sus oficinas y/o en su página web.</p>
        <p>Experian informa: Buscacrédito es una plataforma 100% gratuita para consumidores Al aplicar a la oferta, la
            solicitud del crédito del consumidor será pre aprobada y la finalización de la aplicación al crédito se
            realizará con la entidad financiera.</p>
    </div>
</div>