import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BrokerService } from './core/services/broker.service';
import { SesionService } from './core/services/sesion.service';
import { UtilitiesService } from './core/services/utilities.service';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { DataLayersService } from './core/services/data-layers.service';
import { LoadService } from './core/services/load.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  env = environment;
  title = 'buscacredito';
  txt: any;
  public innerWidth: any;
  public isMobile: any = false;
  public isTablet: any = false;
  public isMedium: any = false;
  public isDesktop: any = false;
  public showEducationBtn:boolean = true;

  constructor(private broker: BrokerService, public sesion: SesionService, public utilities: UtilitiesService, public router:Router, public dataLayer:DataLayersService,private activateRoute: ActivatedRoute) {

    if (environment.gtmPruebas) {
      const script = document.createElement('script');
      script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-PVMDQRP');`;
      document.head.appendChild(script);

      const noscript = document.createElement('noscript');
      noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PVMDQRP"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
      document.body.appendChild(noscript);
    } else {

      const script = document.createElement('script');
      script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-WJGTVCC');`;
      document.head.appendChild(script);

      const noscript = document.createElement('noscript');
      noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WJGTVCC"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
      document.body.appendChild(noscript);

      ///// Hotjar Tracking Code for https://www.midatacredito.com 
      const scriptHotjar = document.createElement('script');
      scriptHotjar.innerHTML = `(function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:1601850,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
      document.head.appendChild(scriptHotjar);
    }
  }

  ngOnInit() {
    localStorage.removeItem('omVisitsFirst');
    localStorage.removeItem('omVisits');
    this.broker.contenido_interface().subscribe((response: any) => {
      this.txt = response;
      this.sesion.datos = response;
    },
      (error) => {
      }
    );
    let url;
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        url = val.url;
        if(url.includes('blog') || url.includes('financial-info')){
          this.showEducationBtn = false;
        }else{
          this.showEducationBtn = true;
        }
      }
    });
    this.activateRoute.queryParams.subscribe(params =>{
      if (params['webview'] != undefined){
        localStorage.setItem('webview', 'webview');
      }
      if (params['webview-ios'] != undefined){
        localStorage.setItem('webview', 'webview-ios');
      }
    });
  }

  goBlog(){
    this.dataLayer.dl_eventoGA4_menu('clic_blog_tag','educación');
    this.router.navigate(['/blog']);
  }
}
