import { Injectable } from '@angular/core';
import { BrokerService } from './broker.service';
import { environment } from 'src/environments/environment';
import { content } from '../interface/content.interface';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class SesionService {

  loggedIn: any;
  datos = {} as content;
  sesionCookie: any = "";
  nameSession: any = "";
  infoComplete: any;
  radicado: any;
  offers: any;
  header = 1;
  path = '';
  isSessionValid = false;
  dataUser: any;
  userPhone: any;
  dataOffer:any = {};

  constructor(private cookieService: CookieService, public broker: BrokerService) {
    this.loggedIn = localStorage.getItem('loggedIn');
    this.sesionCookie = this.cookieService.get('IDSESSIONMDC');
    this.offers = localStorage.getItem('offers');

    //  this.sesionCookie = 'd38c77a0-3ebc-4dce-b18c-30981ea1a447';
    //  this.cookieService.set('IDSESSIONMDC', 'd38c77a0-3ebc-4dce-b18c-30981ea1a447'); 
  }

  isIdsession() {
    return this.sesionCookie != "" && this.sesionCookie != null;
  }

  getName() {
    const cookie = this.cookieService.get('IDSESSIONMDC');
    const data = {
      clientId: environment.clientId,
      idSession: this.cookieService.get('IDSESSIONMDC'),
      country: environment.country
    };
    const SubscribeObservable = new Observable((observer) => {
      this.broker.validar_sesion(data).subscribe((response: any) => {
        observer.next(response.nombre);
      },
        (error: any) => {
          observer.next(undefined);
        }
      );
    });
    return SubscribeObservable;


  }
  validateSession() {
    const cookie = this.cookieService.get('IDSESSIONMDC');
    const data = {
      clientId: environment.clientId,
      idSession: this.cookieService.get('IDSESSIONMDC'),
      country: environment.country
    };

    this.broker.validar_sesion(data).subscribe((response: any) => {
      this.sesionCookie = this.cookieService.get('IDSESSIONMDC');
      this.nameSession = response.nombre;
      this.dataUser = response;
      this.userPhone = response.phone;
    },
      (error: any) => {
        this.broker.cerrar_sesion(data).subscribe((response: any) => {
        }, error => {
        });
        // setTimeout(() => {
          
          this.cookieService.delete('IDSESSIONMDC');
          this.cookieService.delete('IDSESSIONMDC', cookie, environment.domain);
          // this.router.navigate(['/']);
          window.location.href = '/';
          this.sesionCookie = "";
        // }, 200);
      }
    );
  }


}