import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { EducationBlogService } from 'src/app/core/services/education-blog.service';
import { SesionService } from 'src/app/core/services/sesion.service';

@Component({
  selector: 'app-education-blog',
  templateUrl: './education-blog.component.html',
  styleUrls: ['./education-blog.component.scss']
})
export class EducationBlogComponent implements OnInit {

  constructor(public blogService:EducationBlogService, public router:Router, public dataLayer:DataLayersService,public sesion:SesionService) { }

  ngOnInit(): void {
    if (this.sesion.sesionCookie) {
      this.dataLayer.dl_userId('.', 'logueado');
    }
    if (!this.sesion.sesionCookie) {
      this.dataLayer.dl_userId(null, 'anonimo');
    }  
    if(this.blogService.posts.length ==0){
      this.blogService.getPosts();
    }
  }


  goPost(post:any){
    this.dataLayer.dl_eventoGA4_view_blog('clic_open_blog',post.title,new Date().toLocaleDateString());
    this.router.navigate(["/blog/"+post.url]);
  }

}
