import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { BrokerService } from 'src/app/core/services/broker.service';
import { environment } from 'src/environments/environment';
import { SesionService } from '../../core/services/sesion.service';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { FiltersComponent } from '../filters/filters.component';
import { UtilitiesService } from 'src/app/core/services/utilities.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isOpen = false;
  url: any;
  scroll: boolean = false;
  initial = false;
  public nameSesion: any;
  loginUrl: string;
  regUrl: any;
  profileUrl: any;
  dataLogHea: any;
  categorias: any = [];
  styleCols: any = [];
  styleDrop: any = [];
  params: any;
  noColor: boolean = false;

  constructor(
    private router: Router,
    public sesion: SesionService,
    private cookieService: CookieService,
    private broker: BrokerService,
    private activatedRoute: ActivatedRoute,
    private dataLayerService: DataLayersService,
    private utilities: UtilitiesService) {

    this.activatedRoute.queryParams.subscribe((params): any => {
      this.dataLogHea = {
        logo: params['log']
      }
    });

    this.loginUrl = environment.urlLogin;
    this.regUrl = environment.urlRegister;
    this.profileUrl = environment.urlProfile;
    this.nameSesion = this.sesion.nameSession;


    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.url = val.url;
        if (this.url === '/blog' || this.url === '/error' || this.url === '/sitemap' || this.url === '/perfil-bc/financial-info' || this.url.includes('aliado')) {
          this.scroll = true;
        } else {
          this.scroll = false;
        }
        if (this.url === '/') {
          this.initial = false;
        } else {
          this.initial = true;
        }
        if (this.url.includes('blog')) {
          this.noColor = true;
        } else {
          this.noColor = false;
        }
        this.nameSesion = this.sesion.nameSession;

        if (this.sesion.nameSession == undefined || (sesion.nameSession == '' && sesion.isIdsession())) {
          this.sesion.getName().subscribe((result) => {
            this.nameSesion = result;
          });
        }
        if(this.url.includes('aliado') || this.url.includes('ofertas') || this.url.includes('categoria')){
          this.utilities.getUtmParams();
        }
      }
    });

    setTimeout(() => {
      if (window.location.pathname == '/error') { this.scroll = true }
    }, 1500);
  }


  public isMobile: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = event.target.innerWidth <= 425;
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {
    if (this.url != '/sitemap' && this.url != '/error' && this.url != '/perfil-bc/financial-info' && !this.url.includes('aliado')) {
      if (this.url.includes('blog')) {
        this.noColor = false;
      }
      if (window.pageYOffset !== 0) {
        this.scroll = true;
      } else {
        this.scroll = false;
        if (this.url.includes('blog')) {
          this.noColor = true;
        }
      }
    }

    if (window.location.pathname == '/error') {
      this.scroll = true;
    }
  }

  toggleClass() {
    this.isOpen = !this.isOpen;
  }

  ngOnInit() {
    this.menu();
    if (window.innerWidth <= 425) {
      this.isMobile = true;
    }
  }

  cerrarSesion() {
    this.envio_datalayer_header('Salir | Menú usuario');
    const body = {
      clientId: environment.clientId,
      idSession: this.sesion.sesionCookie,
      country: environment.country
    };
    this.broker.cerrar_sesion(body).subscribe((response: any) => {
      this.borrarData();
    },
      (error) => {
        this.borrarData();
      });
  }

  borrarData() {
    window.localStorage.clear();
    this.cookieService.delete('IDSESSIONMDC', '/', environment.domain);
    this.sesion.sesionCookie = null;
    this.router.navigate(['/']);
  }

  menu() {
    this.broker.getMenu().subscribe((response: any) => {
      this.categorias = response.categories;
      for (let i = 0; i < this.categorias.length; i++) {
        if (this.categorias[i].subcategories.length <= 1) {
          this.styleCols[i] = "1";
          this.styleDrop[i] = "0";
        } else {
          this.styleCols[i] = "2";
          this.styleDrop[i] = "-200%";
        }
      }
    },
      (error) => {
      });
  }

  direccionar_logo() {
    let route = ''
    if (!this.sesion.sesionCookie) {
      this.router.navigate(['/']);
    } else {
      this.router.navigate(['/ofertas']).then(() => {
        window.location.reload();
      })
    }

  }

  direccionar(ruta: string) {
    let nombre_boton = this.categorias.filter((elemento: any) => elemento.url == ruta);

    if (nombre_boton.length == 0) {
      let categoria_cortada: string[] = ruta.split('/');
      let categoria_seleccionada = categoria_cortada[0];

      for (let i = 0; this.categorias.length > i; i++) {
        if (this.categorias[i].url == categoria_seleccionada) {
          const nueva_ruta = ruta.replace(`${categoria_seleccionada}/`, '');
          nombre_boton[0] = this.categorias[i].subcategories.find((elemento: any) => nueva_ruta == elemento.url);
        }
      }
    }
    this.envio_datalayer_header(`${nombre_boton[0].name} | Buscacrédito`);

    if (this.sesion.sesionCookie) {
      this.router.navigate(['/ofertas/' + ruta]);
    } else {
      this.router.navigate(['/categoria/' + ruta]);
      //this.filterC.cont_option_choose_cat = 2;
    }
  }

  redirectLogin(main?: boolean) {
    if (this.url.includes('aliado')) {
      localStorage.setItem('companyId', this.sesion.dataOffer.companyId);
      localStorage.setItem('offerId', this.sesion.dataOffer.offerId);
      localStorage.setItem('companyName', this.sesion.dataOffer.companyName);
      localStorage.setItem('productName', this.sesion.dataOffer.productName);
      localStorage.setItem('landingByOffer', this.sesion.dataOffer.landingByOffer);
    }
    if (this.isMobile) {
      if (main) {
        this.envio_datalayer_header('Ingresar2 - mobile | Buscacrédito');
      } else {
        this.envio_datalayer_header('Ingresar - mobile | Buscacrédito');
      }
    } else {
      this.envio_datalayer_header('Ingresar | Buscacrédito');
    }

    this.params = this.router.url.split('/')
    if (this.params[1] == "categoria") {
      if (this.params[2] != undefined && this.params[3] != undefined) {
        localStorage.setItem('url', '/' + this.params[2] + '/' + this.params[3]);
      } else if (this.params[2] != undefined) {
        localStorage.setItem('url', '/' + this.params[2]);
      }
    }
    window.location.href = this.loginUrl;
  }

  /**
   * Este método sirve para enviar información sobre la opción del menú header seleccionada por el usuario
   * enviando el data layer clic_header_menu con la opción seleccionada.
   * @param opcion: Este párametro recibe la opción que el cliente dio clic en el header.
   */
  envio_datalayer_header(opcion: string) {
    this.dataLayerService.dl_eventoGA4_menu('clic_header_menu', opcion);
  }

  goBlog() {
    this.dataLayerService.dl_eventoGA4_menu('clic_header_menu', 'educación');
    this.router.navigate(['/blog']);
  }

}