<div class="errorPageBox">
  <div class="container">
    <div class="d-flex justify-content-center align-items-center vh-100">
      <div class="text-center">
        <h1 class="title-error">¡Oh oh! Algo salió mal</h1>
        <p class="card-body">{{mensaje_error}}</p>
        <button class="btn btn-secundary" routerLink="/">Ir al inicio <i class="arrow right"></i></button>
      </div>
    </div>
  </div>
</div>
