import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MetatagsService } from 'src/app/core/services/metatags.service';

declare var $: any;

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {

	mensaje_error = 'La página a la que intentas ingresar no esta disponible.'

	constructor(
		public metaServ: MetatagsService,
		private _title: Title,
		private _metaService: Meta) { 
	}

	ngOnInit() {
		this._title.setTitle('Buscacrédito | Oops!');
		this._metaService.updateTag({
			name: 'description', content: 'Oops algo salió mal. Vuelve  a nuestro home para ver nuestras ofertas de créditos, seguros, tarjetas de crédito y muchas más!'
		});
		this.metaServ.createCanonicaURL('https://buscacredito.midatacredito.com/error');
		$('#process-modal').modal('hide');
	}
}
