import { Component, OnInit } from '@angular/core';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {
  anio = new Date().getFullYear().toString();
  tyc = environment.enlaces_cloudFront.url + environment.enlaces_cloudFront.terminos_condiciones;
  politica_privacidad = environment.enlaces_cloudFront.url + environment.enlaces_cloudFront.politica_privacidad;
  
  constructor( public sesion: SesionService, private dataLayerService: DataLayersService ) { }

  ngOnInit() {

  }

  /**
   * Esta función envía un evento data layer por medio del tag "clic_footer_menu"
   * @param texto Opción del footer que se enviara por el data layer
   */
  clic_footer_menu( texto:string ){
    this.dataLayerService.dl_eventoGA4_menu( 'clic_footer_menu', texto );
  }
}