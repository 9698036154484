import { Injectable } from '@angular/core';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class ModalService {
	resApi: any;
	resRad: any;
	radUrl: any;
	radParam: any;
	offerInfo: any;

	constructor(
	) { 
	}

	close() {
		this.resApi = '';
		$('#modal-confirm').modal('toggle');
	}

	open(resApi:any, ofertaInfo:any) {
		this.resApi = resApi;
		this.resRad = resApi.radicado;
		this.radUrl = resApi.productUrl;
		this.radParam = resApi.paramsUrl;
		this.offerInfo = ofertaInfo;
		// $('#modal-confirm').modal('show');
		$('#modal-confirm').modal({
			show: true,
			keyboard: false
		});
	}

	termsModal(){
		$("#termsAndConditions").modal({backdrop: 'static', keyboard: false});
		//$("#termsAndConditions").appendTo("body").modal('show');
		$(".modal-backdrop").css({"opacity": ".5", "display": "block"});
	}
}

