import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { BrokerService } from 'src/app/core/services/broker.service';
import { LoadService } from 'src/app/core/services/load.service';
import { OfferService } from 'src/app/core/services/offer.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { UtilitiesService } from 'src/app/core/services/utilities.service';

@Component({
  selector: 'app-bc-connect',
  templateUrl: './bc-connect.component.html',
  styleUrls: ['./bc-connect.component.scss']
})
export class BcConnectComponent implements OnInit {

  public dataContent: boolean = true;
  public resultContent: boolean = false;
  public success: boolean = true;
  public employmentStatus: string = '';
  public amountRequired: string = '';
  public correctAmount: boolean = false;
  public textAprroved = 'PREAPROBADO';

  // ACTIVIDADES_ECONOMICAS
  actividades_economicas: any = [
    { tipo: 1, texto: 'Estudiante' },
    { tipo: 2, texto: 'Empleado' },
    { tipo: 3, texto: 'Independiente' },
    { tipo: 4, texto: 'Pensionado' },
    { tipo: 5, texto: 'Otro', texto_Alt: 'Desempleado' }
  ];

  public flowsBC: any = {
    '901255144': (result: any) => { this.solventaFlow(result); } //Solventa
  };

  public responseOK: any;


  constructor(public sesion: SesionService, public load: LoadService, public utilities: UtilitiesService, public offer: OfferService, public broker: BrokerService, private cookieService: CookieService, public router:Router) { }

  ngOnInit(): void {

  }

  activeResult() {
    this.load.isInProcess = true;
    let companyId = this.offer.offerSelected.company.id;
    companyId = (companyId == null ? '' : companyId);
    let offerId = this.offer.offerSelected.offerId;
    let sourceOfTraffic = '';
		let campaignOfTraffic = '';
		let mediumOfTraffic = '';
    if((Object.keys(this.utilities.utmParams).length > 0)){
			for (const key in this.utilities.utmParams) {
				if (key.toLowerCase() == 'utm_source') {
					sourceOfTraffic = this.utilities.utmParams[key]
				}
				if (key.toLowerCase() == 'utm_medium') {
					mediumOfTraffic = this.utilities.utmParams[key]
				}
				if (key.toLowerCase() == 'utm_campaign') {
					campaignOfTraffic = this.utilities.utmParams[key]
				}
			  }
		}
    let bodyUpdateProfile = {
      idSession: this.cookieService.get('IDSESSIONMDC'),
      occupationCode: this.employmentStatus,
      cellPhone: this.sesion.userPhone,
      country: "CO"
    }
    // let employmentSituationtext = this.actividades_economicas.find((ele:any) => ele.tipo == this.employmentStatus).texto;
    let bodyOffer = {
      idSession: this.sesion.sesionCookie,
      companyId: companyId,
      amount: parseFloat(this.amountRequired),
      // employmentSituation : this.actividades_economicas.find((ele:any) => ele.tipo == this.employmentStatus).texto,
      // phone : this.sesion.userPhone.toString(),
      offerId: offerId,
      sourceOfTraffic : sourceOfTraffic,
			campaignOfTraffic : campaignOfTraffic,
			mediumOfTraffic : mediumOfTraffic
    }

    this.broker.updateInfo(bodyUpdateProfile).subscribe(
      (result: any) => {
        this.dataContent = false;
        this.resultContent = true;
        if (result.code == "200") {
          this.broker.aplicarOferta(bodyOffer).subscribe({
            next: (result: any) => {
              if (result.orderNumber && result.responseConecta != undefined) {
                if (result.responseConecta.response == "Preapproved") {
                  if (companyId != null) {
                    //Selector de flujos applyoffer
                    this.flowsBC[companyId](result);
                  }
                  this.success = true;
                  this.load.isInProcess = false;
                  this.load.isConectaFail = false;
                  this.load.imgBcConectaDesktop = this.load.imgsBcConecta.d_success;
                  this.load.imgBcConectaMobile = this.load.imgsBcConecta.m_success;
                } else {
                  // this.success = false;
                  // this.load.isInProcess = false;
                  // this.load.isConectaFail = true;
                  // this.load.imgBcConectaDesktop = this.load.imgsBcConecta.d_fail;
                  // this.load.imgBcConectaMobile = this.load.imgsBcConecta.m_fail;
                  this.failProcess(true);
                }
              } else {
                // this.success = false;
                // this.load.isInProcess = false;
                // this.load.isConectaFail = true;
                // this.load.imgBcConectaDesktop = this.load.imgsBcConecta.d_fail;
                // this.load.imgBcConectaMobile = this.load.imgsBcConecta.m_fail;
                this.failProcess();
              }
            },
            error: (error: any) => {
              // this.success = false;
              // this.load.isConectaFail = true;
              // this.load.imgBcConectaDesktop = this.load.imgsBcConecta.d_fail;
              // this.load.imgBcConectaMobile = this.load.imgsBcConecta.m_fail;
              // this.load.isInProcess = false;
              this.failProcess();
            }
          }
          );
        }
        else {
          // this.success = false;
          // this.load.isInProcess = false;
          // this.load.isConectaFail = true;
          // this.load.imgBcConectaDesktop = this.load.imgsBcConecta.d_fail;
          // this.load.imgBcConectaMobile = this.load.imgsBcConecta.m_fail;
          this.failProcess();
        }
      },
      (error: any) => {

        // this.success = false;
        // this.load.isConectaFail = true;
        // this.load.imgBcConectaDesktop = this.load.imgsBcConecta.d_fail;
        // this.load.imgBcConectaMobile = this.load.imgsBcConecta.m_fail;
        // this.load.isInProcess = false;
        this.failProcess();
      });
    // setTimeout(() => {
    //   this.dataContent = false;
    //   this.resultContent = true;
    //   this.load.isInProcess = false;
    // }, 3000);
    // this.dataContent = false;
    // this.resultContent = true;
  }
  textInputNum(target: EventTarget | null) {
    // console.log(this.offer.offerSelected);
    let pattern = /[0-9]/;
    let textElement = (target as HTMLInputElement);
    let val = textElement.value;
    val = val.replace("$", "");
    val = val.split(".").join("")
    if (!pattern.test(val)) {
      textElement.value = "";
      this.amountRequired = "";
      this.correctAmount = false;
      return false;
    }
    if (val != "") {
      this.amountRequired = val;
      let range = this.offer.offerSelected.detail.filters.find((ele: any) => ele.id == 3);
      this.correctAmount = (parseInt(this.amountRequired) >= range.values.min && parseInt(this.amountRequired) <= range.values.max);
      textElement.value = this.utilities.currency(val)
    }
    return true;
    // return this.currency(val)
    // let val = parseInt(textElement.value);
  }

  /**
 * Funcion que estandariza los datos del input check para llamar a la funcion que gestiona la manera de filtrar
 * @param target elemento check
 * @param id id del elemento
 */
  checkInput(target: EventTarget | null) {
    let checkElement = (target as HTMLButtonElement);
    let checks = checkElement.parentElement?.children;
    if (checks != undefined)
      for (let index = 0; index < checks.length; index++) {
        const element = checks[index];
        element.classList.remove("active");

      }
    checkElement.classList.add("active");
    let val = checkElement.value;
    this.employmentStatus = val;
    // this.offer.setFilterOffer(id, val);
  }

  /**
   * Funcion para validar el numero de telefono y que sea un celular mediante un pattern
   */
  validPhone() {
    let pattern = /^(?!.*(\d)\1{3,})(^(3)\d*$)/;
    let isCorrectPattern = pattern.test(this.sesion.userPhone);
    let lengthPhone = (this.sesion.userPhone.length == 10);
    return isCorrectPattern && lengthPhone;
  }

  /**
   * Funcion para veirificar si los datos del formulario son correctos
   */
  checkData() {
    return this.employmentStatus != "" && this.correctAmount && this.validPhone();
  }

  /**
   * Funcion para mostrar los datos y hacer logica del flujo solventa
   * @param result el resulta del servicio de applyOffer
   */
  solventaFlow(result: any) {
    let bodySolventa = {
      idSession: this.cookieService.get('IDSESSIONMDC'),
      cellPhone: this.sesion.userPhone,
      country: "CO"
    }
    let offerAux = this.offer.offersList.find((ele: any) => ele.company.id == this.offer.offerSelected.company.id && ele.offerId == this.offer.offerSelected.offerId);

    offerAux.preselecta.orderNumber = result.orderNumber;
    offerAux.preselecta.date = result.applyDate;
    offerAux.preselecta.urlRedirect = result.applyDetail.urlRedirect;
    this.offer.offerSelected.isApplied = true;
    const urlRedirect = result.applyDetail.urlRedirect;
    const urlEncriptedData = result.applyDetail.urlEncriptedData;
    if (urlRedirect != "" || urlRedirect != undefined) {
      // let urlRedirect = result.applyDetail.urlRedirect;
      this.broker.sendEncriptedData(bodySolventa, urlEncriptedData + result.encryptedInfo);
      this.responseOK = result.responseConecta;
      this.textAprroved = (result.orderNumber % 2 == 0 ? 'PREAPROBADO' : 'APROBADO')
      // window.open(urlRedirect, '_blank');
    }
  }

  /**
   * Funcion para redireccionar al alidado
   */
  goAllie() {
    window.open(this.responseOK.detail.redirectUtm, '_self');
    this.load.closeBcConecta();
  }

  /**
   * Funcio para manejar el flujo de error al moemento de aplicar una oferta en conecta
   * @param isRejected parametro para saber si la oferta fue rechazada o fallo otro consumo del servicio
   */
  failProcess(isRejected:boolean = false){
    this.dataContent = false;
    this.resultContent = true;
    this.success = false;
    this.load.isInProcess = false;
    this.load.isConectaFail = true;
    this.load.isConectaRejected = isRejected;
    this.load.imgBcConectaDesktop = this.load.imgsBcConecta.d_fail;
    this.load.imgBcConectaMobile = this.load.imgsBcConecta.m_fail;
    if(isRejected){
      setTimeout(() => {
        if(this.load.isConectaFail){
          let categorySel = this.offer.categoriesActive.find((element: any) => { return element.id == this.offer.offerSelected.detail.categoryId });
          this.offer.offersList = this.offer.offersList.filter((ele:any) => ele.idOffer != this.offer.offerSelected.idOffer);
          this.offer.offersFil = this.offer.offersFil.filter((ele:any) => ele.idOffer != this.offer.offerSelected.idOffer);
          this.offer.offerSelected = undefined;
          this.load.isConectaFail = false;
          this.load.isConectaRejected = false;
          this.load.closeBcConecta();
          this.router.navigate(['/ofertas/'+categorySel.url]);
        }
      }, 15000);
    }
  }



}
